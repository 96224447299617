a {
  text-decoration: none;
  color: blue;
}

nav.header ul {
  list-style-type: none;
  margin: 0;
  color: white;
  padding: 0;
}

nav.header li {
  display: inline-block;
  margin: 1em .2em 1em 0;
}

nav.header li:after {
  content: " >";
}

nav.header li:last-child:after {
  content: "";
}

nav.header a {
  color: white;
}

nav.list {
  display: flex;
  flex-direction: column;
}

nav.list div.draggable {
  background-color: white;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 5px;
  margin: 8px;
}

.measure-holder {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.measure {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: .1em;
  min-width: 5em;
  border-radius: .5em;
  box-shadow: 0 0 5px 0px #00000040;
  padding: .5em;
  background-color: #403D58;
}

.measure.selected {
  background-color: #0C7C59;
}

.measure p {
  margin: 0;
}

.measure p.measure-number {
  font-size: 1.2em;
}

.measure p.measure-tempo {
  font-size: 1.5em;
  font-weight: bold;
}

.percent, .regular {
  font-weight: normal;
}

.measure p.measure-goal {
  color: #777;
}

.measure.selected p.measure-goal {
  color: #e0e0e0;
}

nav.header {
  background-color: #141115;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
}

.header-functions {
  float: right;
}

@media (max-width: 1100px) {
  nav.header {
    flex-direction: column;
  }
}

.header-functions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
